<template>
  <div id="data-list-list-view" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <new @add="addDataSideBar" :products="products" :warehouses="warehouses" :isSidebarActive="newSidebar"
         @closeSidebar="newSidebar= false"/>

    <detail @update="updateDataSideBar" :products="products" :warehouses="warehouses" :isSidebarActive="detailSidebar"
            @closeSidebar="detailSidebar = false" :data="sidebarData"/>

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
              :data="inputs">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4">

            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click.stop="sidebarData= selected, detailSidebar= true">
                            <span class="flex items-center">
                              <feather-icon icon="EyeIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Ver detalle</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <!-- Add new -->
          <div
              class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="newSidebar= true">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Agregar</span>
          </div>
        </div>
        <!-- Items per page -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ inputs.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : inputs.length }} de {{
                queriedItems
              }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=30">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="id">Nro. Doc</vs-th>
        <vs-th sort-key="date">Fecha</vs-th>
        <vs-th sort-key="hour">Hora</vs-th>
        <vs-th sort-key="warehouse">Almacén</vs-th>
        <vs-th sort-key="sku">SKU</vs-th>
        <vs-th sort-key="motive">Motivo</vs-th>
        <vs-th sort-key="origin">Origen</vs-th>
        <vs-th sort-key="user">Usuario</vs-th>
        <vs-th>Detalle</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="font-medium truncate">{{ tr.id.substr(0, 6).toUpperCase() }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.createdAt | date(true) }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.createdAt | time(true) }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.warehouse.name }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.products[0].sku }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.motive }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.origin }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.userName }}</p>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon title="Detalle" class="ml-2" icon="EyeIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="sidebarData= tr, detailSidebar= true"/>
            <feather-icon v-if="user === 'christian@salsa.pe'" @click="deleteInput(tr, indextr)"
                          class="ml-2 cursor-pointer" icon="TrashIcon"
                          svgClasses="h-4 w-4"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import New from './New'
import Detail from "./Detail";

import { auth, db } from '@/firebase/firebaseConfig'

export default {
  name: 'ListInputs',
  components: {
    Detail,
    New
  },
  data() {
    return {
      selected: null,
      itemsPerPage: 10,
      isMounted: false,
      inputs: [],
      initProgress: false,

      // Data Sidebar
      newSidebar: false,
      detailSidebar: false,
      sidebarData: {},

      products: [],
      warehouses: []
    }
  },
  computed: {
    list: {
      get() {
        return this.inputs
      }
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    },
    user () {
      return auth.currentUser.email
    }
  },
  async created() {
    try {
      this.initProgress = true
      await this.loadProducts()
      await this.loadInputs()
      await this.loadWarehouses()
    } catch (e) {
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted() {
    this.isMounted = true
  },
  methods: {
    /**
     * Get inputs
     * @returns {Promise<void>}
     */
    async loadInputs() {
      const querySnapshot = await db.collection("inputs").orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let input = {
          id: doc.id,
          ...doc.data(),
          motive: doc.data().motive,
          createdAt: doc.data().createdAt.toDate()
        }
        this.inputs.push(input)
      })

    },
    /**
     * Get warehouses
     * @returns {Promise<void>}
     */
    async loadWarehouses() {
      this.warehouses = []
      const querySnapshot = await db.collection("warehouses").orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let warehouse = {
          id: doc.id,
          ...doc.data()
        }
        this.warehouses.push(warehouse)
      })
    },
    /**
     * Get products
     * @returns {Promise<void>}
     */
    async loadProducts() {
      this.products = []
      const querySnap = await db.collection("products").orderBy('createdAt', 'desc').get()
      querySnap.forEach((doc) => {
        let product = {
          id: doc.id,
          name: doc.data().name,
          sku: doc.data().sku,
          label: doc.data().name + '-' + doc.data().sku,
          cost: doc.data().price
        }
        this.products.push(product)
      })
    },
    filterData() {
      this.filter = !this.filter
    },
    /**
     * Add data of sidebar
     * */
    addDataSideBar(o) {
      console.log(o)
      this.list.unshift(o)
    },
    /**
     * Update data of sidebar
     * */
    updateDataSideBar(o) {
      console.log(o)
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Delete movement
     * @param id
     * @returns {Promise<void>}
     */
    async deleteInput (input, index) {
      try {
        this.initProgress = true
        await db.collection('inputs').doc(input.id).delete()
        this.inputs.splice(index, 1)
        this.$vs.notify({
          color: 'success',
          title: 'Ingreso',
          text: 'Ingreso eliminado.'
        })
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    }
  },
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
